.container_info_div_LOINC{

  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -12px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.container_icon_LOINC_info{
  width:20px;
  height: 20px;

}


.panel_decalage{
  font-size:14px;
  margin-left:30px;
}
