
.container_dicotable{
/*  width: 80%;*/
  margin-left: auto;
  margin-right: auto;
}

.container_dicotable_infinite{
/*  width: 80%;*/
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  overflow: auto ;
}


.sitcky{
  position: sticky;
  top: 0;
  background: white;
}

.index_scroll{
  z-index: 100;
}

.dico_table{
  border-spacing: 0;
  border: 1px solid black;
  background-color: #fff;
  /*margin-bottom:10px;*/
  overflow-y: auto;
  width: 100%;
  margin:1%;

}


.dico_table_sticky{
  border-spacing: 0;
  border: 1px solid black;
  border-top:0;
  background-color: #fff;
  /*margin-bottom:10px;*/
  overflow-y: auto;
  width: 100%;

}

tr:last-child.td {
  border-bottom: 0;
}

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-size: 12px;
}

td:last-child{
  border-right: 0;
}

.dico_table_sticky th {
  border-top : 1px solid black;
}

.select_table{
  padding-bottom: 10px;
}


.container_select_pre{
  display: flex;
  flex-direction: column;
}

.select_add{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}


.select_pre{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}


.dash_nav_page{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-bottom : 0%;
  justify-content: center;


}
