
.dash_container_account{
  display: flex;
  flex-flow: row wrap;
  padding: 30px;
}


.dash_account {
  text-align: center;
  padding: 1%;
  margin-right: auto;
  margin-left: auto;
  width: 60%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  min-height: 600px;
}

.dash_account_input{
  margin:1%;
}

.dash_validation{
  margin-top:3%;
}
