


.dash_container_client_all{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
}

.dash_container_client_action{
  display: flex;
  justify-content: center;
  flex-direction : row ;


  height: auto;
  padding: 30px;


}


.dash_container_client_info{

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dash_client_info{
  text-align: center;
  padding: 1%;
  margin: 20px;
  width: 500px;
  height: auto;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

}


.dash_client_actions {
  text-align: center;
  padding: 1%;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 240px;

  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

}

.dash_client_actions_actif{
  background-color: #fff;
  transition: transform 0.2s;
}

.dash_client_actions_unactif{
  background-color: #DDD;

}

.dash_client_actions_actif:hover {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  transform: scale(1.03);
}


.review_label{
  color : red;
}

.review_client_label{
  color : green;
}



.chapitre_tooltip{
  max-width: 80%;
  white-space: pre-wrap;
}