.button {
    margin: 0px 20px;
    padding: 10px 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4db6eb;
    border: none;
    border-radius: 40px;
    box-shadow: 0 6px #999;
}

.button:hover {
    background-color: #099feb;
}
.button:active {
    background-color: #f5b23d;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
}

.auditGreen {
  background-color: #28a745;
}

.auditGreen:hover{
  background-color: #085619;
}

.auditYellow{
  background-color: #ffe507;
}

.auditYellow:hover{
  background-color: #ffc107;
}

.auditOrange{
  background-color: #fd7e14;
}

.auditOrange:hover{
  background-color: #c98727;
}

.auditRed{
  background-color: #dc3545;
}



.auditRed:hover{
  background-color: #7a121c;
}

.auditBlack{
  background-color: #333333;
}



.auditBlack:hover{
  background-color: #000000;
}


.searchOn{
  background-color: #333333;
}

.searchOn:hover{
  background-color: #000000;
}

.searchOnly{
  background-color: #ff0000;
}

.searchOnly:hover{
  background-color: #970202;
}


.btn_search{
  width: auto;
  font-size: 16px;
  margin:10px;
}

.dashbutton{
  font-size: 12px;
  /* margin:5%; */
}


.labo_account_btn{
  background-color: #1A385E;
  margin-top:2%;

}
.labo_account_btn:hover{
  background-color: #132945;

}

.btn_color_search{
  background-color: #1A385E;
}

.btn_color_search:hover{
  background-color: #132945;
}



.btn_page_next{
  width: 30px;
  height: 30px;
  padding: 0px;
  border-radius: 50%;
}

.btn_page_prev{
  width: 30px;
  height: 30px;
  padding: 0px;
  border-radius: 50%;
  margin-right: 0px;
}

/**************/
/**NAV BUTTON**/
/**************/
.navbutton {
  margin: 0px 20px;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #099feb;
  border: none;
  border-radius: 40px;
  box-shadow: 0 6px #999;
}

.navbutton:hover {
  background-color: #045c88;
}

.navbutton:active {
  background-color: #f5b23d;
  box-shadow: 0 3px #666;
  transform: translateY(4px);
}


.relatifNav{
  width: 30px;
  height: 30px;
  padding: 0px;
}


.btn_margin{
  margin-bottom: 10px;
}

.validation_btn{
  background-color: #099feb;
  width:200px;
}


.validation_btn:hover{
  background-color: #045c88;
}


.changer_dico_btn{
  background-color: #099feb;
  width:280px;
}


.changer_dico_btn:hover{
  background-color: #045c88;
}





.button_end{
  width:60%;
  margin-left: auto;
  margin-right: auto;
}


.btn_dashboard{
  height: 40px;
  padding: 0;
  margin: 0;
  width: 150px;
  margin-left: 20px;
  margin-right: 30px;
  background-color: #1A385E;
}

.btn_dashboard:hover{
  background-color: #132945;

}


.anomalieColorOff{
  background-color: #616685;
}

.anomalieColorOff:hover{
  background-color: #545A85;
}

.anomalieColor{
  background-color: #dc3545;
}


.anomalieColor:hover{
  background-color: #7a121c;
}


.btn_tuto{
  background-color: #1A385E ;
  width: 90px;
  height: 30px;
  padding: 0;
  font-size: 15px;
}

.btn_tuto:hover{
  background-color: #F2CB88;
}

.dashclientbutton{
  margin:0;
}

.shortcut{
  background-color: #4f4f4f;
}

.shortcut:hover{
  background-color: #272626;
}


.btn_modif_dico{
  font-size: 14px;
  padding: 5px 11px;
  border-radius: 20px;
}


.btn_questionnaire_prev{
  background-color: white;
  color: #1A385E;
  font-size: 16px;
  padding: 8px 20px;
}

.btn_questionnaire_prev:hover{
  background-color: rgb(163, 162, 162);
}

.btn_questionnaire{
  background-color: #1A385E;
  font-size: 16px;
  padding: 8px 20px;
}



.btn_questionnaire:hover{
  background-color: #0e1b2c;
}


.upload_btn{
  width: 100px;
  height: 30px;
  font-size: 16px;
  padding: 0px;
}


.upload_btn_technidata{
  width: 150px;
  height: 30px;
  font-size: 16px;
  padding: 0px;
}