/**************/
/***Bootstrap Progress-bar ****/
/**************/


.progressContainer{
  width :100%;
  margin-right: auto;
  margin-left: auto;
}
/*
@media (min-device-height: 1440px) and (min-height:1300px) {
  .progressContainer{
    width :100%;
    margin-right: auto;
    margin-left: auto;
    position:absolute;
    bottom:0;
  }
}*/

.progressMaster{
  display: flex;
  overflow:hidden;
  line-height: 0;
  font-size: .75rem;
  background-color: #e9ecef;
  height: 35px;
  border-radius: 25px;
}
.progressBar{
  display: flex;
overflow: hidden;
color: #000;
font-size: 15px;
flex-direction: column;
justify-content: center;
text-align: center;
white-space: nowrap;
background-color: #007bff;
transition: width .6s ease;
}
.done{
background-color: #28a745 !important;
}
.warn{
background-color: #ffc107 !important;
}
.dang{
  background-color: #dc3545 !important;

}




.progressMaster::after::before{
  box-sizing: border-box;
}
.legend_progress_bar {
  display: flex;
  justify-content: center;
  align-items: space-around;
  flex-flow: row wrap;
}

.legend_contain{
  display: flex;
  flex-direction: row;
  margin-right: 1%;
  width: 250px;
  justify-content: center;
  align-items: center;
}

.foo {
  float: left;
  width: 20px;
  height: 20px;
  margin: 5px;
  border-style: none;
  border-radius: 5px;
}
.green {
  background: #28a745;
}
.yellow {
  background: #ffc107;
}
.red {
  background: #dc3545;
}
