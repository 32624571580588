.maintenance{
  width: 60%;



}


.container_maintenance{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}


.container_maintenance p{
  font-size: 20px;
  font-weight: bold;
}


.maintenance_msg{
  font-size: 20px;
  font-weight: normal;
}