.container_quesitonnaire_btn{
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-around;
}

.label_radio_questionnaire{
    display: flex;
    flex-direction: column;
}



.radio_button_control{
    font-size:14px;
}

.error_questionaire{
    color:red;
}