.labo_img{
  width: 50%;
}

.labo_img_container{
  width: 50%;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.labo_account{
  background-color: #4cb4ec;
  width: 50%;
  height: 100vh;
  margin-left:50%;
  position: fixed;
  overflow: auto;
}

.labo_account_main_container{
  margin:auto;
  padding: 20px;
  text-align: center;
}

.account_title{
  text-align: center;
  color: white;
  font-size: 30px;
}

.add_expert {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  padding:2%
}

.add_expert p {
  margin: 10px;
}

.add_button{
  background: url('/src/img/add_button.svg') no-repeat;
  width:35px;
}

.add_button:hover{
  background: url('/src/img/add_button_hover.svg') no-repeat;
}

.add_button:active {
  background: url('/src/img/add_button_clic.svg') no-repeat ;
  transform: translate(4px,4px);
}
