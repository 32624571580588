/*********/
/***MAP***/
/*********/





.container_propositions_map_search {
    display: flex;
  /*  justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;*/
    flex-direction: column;
    width:97%;

    padding: 1%;
    background-color: #d9e8ff;
    color: #545b6f;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

    margin: 1%;


  overflow: auto;
  height: 66vh;

}




.container_propositions_cards_map_search {
    text-align: center;
    padding: 1%;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 300px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
}
.container_propositions_cards_map_search:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
}



.img_agree_test_map {
    background: url(../../img/verifier.svg) no-repeat;
    background-position-x: 0px;
    background-position-y: 0px;
    background-size: 20px;
   z-index: 0;
}

.commun_map{
  padding-top: 2px;
padding-bottom: 2px;
}

.result_center{
  margin-left:auto;
  margin-right: auto;
}
