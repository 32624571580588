.dico_modal {
  position:fixed;
  background: white;
  top:2%;
  left:2%;
  color: black;
  border-radius: 25px;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
  padding: 10px;
  width: 96%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}


.display-block {
  display: block;
}

.display-none {
  display: none;
}

.dico_nav{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3%;
}
