/********************/
/***IDENTIFICATION***/
/********************/
body,html,#root,.app {
    width: 100%;
    height: 100%;
}


p {
    color: black ;
}



.Identification {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  background-color: #4DB6EB;
}
.login_page_leftside {
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.login_page_img {
    width: 70%;
}
.login_page_rightside {
    width: 50%;
    height: 100vh;
    background-color: #4DB6EB;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginForm {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
}
.input_size_login {
    width: 35%;
}
.input {
    width: 100%;
    margin-top: 15px;
    padding: 12px;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
    background: #fff;
    border-radius: 15px;
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    color: black;
}


.btns_connexion{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
}

.submitButton {
    padding-top: 16px;
}
.btn {
    margin: 0px 20px;
    padding: 10px 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #4db6eb;
    background-color: #fff;
    border: none;
    border-radius: 40px;
    box-shadow: 0 6px #999;
}
.btn:hover {
    color: #fff;
    background-color: #099feb;
}
.btn:active {
    background-color: #f5b23d;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
}
.logo_onaos{
    margin: 0px;
    width: 25%;
}
.container_forgot_mdp {
    width: 35%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
.forgot_mdp {
    font-size: 14px;
    text-align: right;
    text-decoration: none;
    color: #1A385E;
}
