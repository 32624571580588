@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*********************/
/***GENERAL SECTION***/
/*********************/

.body_back{
  background: url(/static/media/background_uni.50501904.png) no-repeat;
  background-size: cover;
  font-family: "Montserrat", sans-serif;
background-color: #4db6eb;
}

p {
    color: black ;
}

.logo_onaos_mapping{
    width: 7%;
    margin-right: 30px;
}

.App {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;

}
hr {
    width: 30%;
    height: 2px;
    background-color: #545b6f;
    border: none;
}
h1 {
    font-size: 30px;
}
p {
    font-size: 16px;
}
.characters {
    font-weight: bold;
}
.margin_title {
    -webkit-margin-before: 0em;
            margin-block-start: 0em;
}
.title_client {
  /*  font-size: 50px;*/
    text-align: left;
    color: #545b6f;
}
.title_expert {
    text-align: left;
    color: #545b6f;
}
/*
.interligne {
    line-height: 1em;
}
.sizing_entree {
    padding-left: 60px;
}
*/
.resize {
    width: 100%;
    padding-bottom: 10px;
}
.title_propositions {
    font-size: 30px;
    color: #545b6f;
    text-align: left;
    margin-bottom: 30px;
}
/*
.alignement {
    text-align: left;
    margin-left: 20px;
}*/
.center {
    text-align: center;
}


/*********************/
/**CONTAINER SECTION**/
/*********************/
.container_onaos {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-flow: row wrap;
  /*  padding: 10% 5% 2% 5%;*/
    padding: 0% 3% 0% 3%;
}
.container_one_card {
    width: 600px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    color: #545b6f;
    text-align: left;
    font-size: 25px;
}
.container_client {
    width: 600px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    color: #545b6f;
    text-align: left;
    font-size: 25px;
}
.container_propositions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;

    width: 1000px;
    height: 400px;
    padding: 20px;
    background-color: #d9e8ff;
    color: #545b6f;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    overflow: scroll;
}
.container_propositions_cards {
    text-align: center;
    padding: 1%;
    margin-bottom: 20px;
    width: 300px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
}
.container_propositions_cards:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
}
.container_compteur {
    font-weight: bold;
    text-align: end;
    padding-right: 50px;
}
.container_final {
    margin: 50px 2% 0px 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
}
.container_final_propositions {
    width: 510px;/*600*/
    padding: 10px 30px;
    background-color: #ffffff;
    /*border: 2px solid #ffe140;*/
    border-radius: 20px;
  /*  box-shadow: 0px 0px 10px 0px rgba(242, 203, 136); */
    box-shadow: 0 0 8px rgba(0, 0, 0,0.2);
    text-align: left;
    font-size: 25px;
    color: #545b6f;
    margin-bottom: 20px;
}
.container_button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.container_final_propositions_adjust {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-flow: column wrap;
}
/**************/
/***ACCEPTER***/
/**************/
.agree {

    background-color: #4db6eb;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 5px;
}
.container_client_agree {
    width: 500px;
    background-color: #4db6eb;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 5px;
}
.img_agree {
    background: url(/static/media/verifier.ea4f0b17.svg) no-repeat;
    background-position-x: 10px;
    background-position-y: 10px;
    background-size: 50px;
}
/**************/
/***REFUSER****/
/**************/
.reject {
    background-color: #E13700;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 5px;
}
.container_client_reject {
    width: 500px;
    background-color: #E13700;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 5px;
}
.img_reject {
    background: url(/static/media/annuler.342967f8.svg) no-repeat;
    background-position-x: 10px;
    background-position-y: 10px;
    background-size: 50px;
}
/****************/
/***ERROR LOG****/
/****************/
.errorLog {
    margin: 10px 0 0 0;
    color: #E31410;
}


@media (min-height: 1800px) {
  .resize{
    position:absolute;
    bottom:10%;
  }
}

.base_type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 15px;
}

.p_base_type{
  font-weight: bold;
  font-size: 18px;
  color: #315d79;
  margin-left: 1%;
}

.home{
  background: url(/static/media/home_btn.ce4137b6.svg) no-repeat;
  width:40px;
  height: 40px;
  background-size: cover;
  margin-left: 20px;
  margin-right: 10px;
}




.home:hover{
  background: url(/static/media/home_btn_hover.fb40364c.svg) no-repeat;
  background-size: cover;
}


.home:active{
  background: url(/static/media/home_btn_clic.0b474a1c.svg) no-repeat;
  transform: translate(4px, -1px);
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

/********************/
/***IDENTIFICATION***/
/********************/
body,html,#root,.app {
    width: 100%;
    height: 100%;
}


p {
    color: black ;
}



.Identification {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  background-color: #4DB6EB;
}
.login_page_leftside {
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.login_page_img {
    width: 70%;
}
.login_page_rightside {
    width: 50%;
    height: 100vh;
    background-color: #4DB6EB;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginForm {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
}
.input_size_login {
    width: 35%;
}
.input {
    width: 100%;
    margin-top: 15px;
    padding: 12px;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
    background: #fff;
    border-radius: 15px;
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    color: black;
}


.btns_connexion{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
}

.submitButton {
    padding-top: 16px;
}
.btn {
    margin: 0px 20px;
    padding: 10px 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #4db6eb;
    background-color: #fff;
    border: none;
    border-radius: 40px;
    box-shadow: 0 6px #999;
}
.btn:hover {
    color: #fff;
    background-color: #099feb;
}
.btn:active {
    background-color: #f5b23d;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
}
.logo_onaos{
    margin: 0px;
    width: 25%;
}
.container_forgot_mdp {
    width: 35%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
.forgot_mdp {
    font-size: 14px;
    text-align: right;
    text-decoration: none;
    color: #1A385E;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modal-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position:fixed;
  background: white;
  width: 30%;
  top:50%;
  left:50%;
  color: black;
  transform: translate(-50%,-50%);
  border-radius: 25px;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
  padding: 30px;
}

.modal-position{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
}

.modal-position button {
  margin: 0px 20px;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4db6eb;
  border: none;
  border-radius: 40px;
  box-shadow: 0 6px #999;
}

.modal-position button:hover {
  color: #fff;
  background-color: #00679b;
}

.modal-position button:active {
  background-color: #f5b23d;
  box-shadow: 0 3px #666;
  transform: translateY(4px);
}

.modal-label{
/*  display: flex;
  flex-direction: column;*/
  font-size: 20px;

  text-align: center;
}

.modal-label input {
  box-sizing: border-box;
  outline: none;
  border: 2px solid #1189de;
  border-radius: 4px;
  color: #292929;
  width: 100%;
  margin-top: 15px;
  padding: 12px;
  font-size: 18px;
  background: #fff;
  border-radius: 25px;
}

.modal-btn{
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-top: 10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.error {
  color:red;
  margin-top:20px
}

.selectbox_comm{
  width: 500px;
  margin-top:10px;
  margin-bottom: 10px;
  font-size: 16px !important;
}


.interligne {
    line-height: 1em;
}
.sizing_entree {
    padding-left: 40px;
}
.characters {
    font-weight: bold;
}


.alignement {
    text-align: left;
    margin-left: 20px;
}


.sizing_entree_search {
    padding-left: 10px;
    font-size: 12px;
}

.sizing_title_search {
    font-size: 24px;
}

.tooltip {
    text-align: left;
    margin-left: 2px;
    font-size: 12px;
}

.icone_caution_map{
  margin-left: 10px;
  margin-bottom: -1px;
      transition: transform 0.2s;
}

.icone_caution_map:hover{
  transform: scale(1.03);

}

.inside{
  padding: 0 !important;
margin: 0 !important;
}

.panel_label{
  cursor: pointer;
}


.panel_Oui{
  border-radius: 5px;
  padding: 1%;
  background-color: #88eb42;
  color: #fff;
}


.panel_Oui:hover{
  box-shadow: 0 0 4px rgba(0,0,0,.3);
  cursor: pointer;
}

.panel_Non{
  border-radius: 5px;
  padding: 1%;
  background-color: #eb7f59;
  color: #fff;
}


.restreint_green{
  color:#509f17;
}


.info_tooltip{

  width: 80%;
  /*white-space: pre-wrap;*/
  z-index: 100;
}
/*********/
/***MAP***/
/*********/

.tmp_recherche_dash_block{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
  align-items: center;
}
.full_container_propositions{
  width: 60%;
}



.container_propositions_map {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;

    width:97%;
    height: 520px;
    padding: 30px;
    background-color: #d9e8ff;
    color: #545b6f;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    /*overflow: scroll;*/
    overflow: auto;

}




.container_propositions_cards_map {
    text-align: center;
    padding: 1%;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 300px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

.container_propositions_cards_map_scale{
  transition: transform 0.2s;

}

.container_propositions_cards_map_scale:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
}



.outloinc_client{
  background-color: #00000010;
}

.from_other_client{
  background-color: #FFC7BF;
}


.from_ca {
  background-color: #94dbce;
}

.add_client{
  background-color: rgba(250,250,250,0.8);
}


.container_one_card_map{
  width: 600px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  color: #545b6f;
  text-align: left;
  font-size: 25px;
}
.img_reject_test_map {
    background: url(/static/media/annuler.342967f8.svg) no-repeat;
    background-position-x: 0px;
    background-position-y: 0px;
    background-size: 20px;

}
.img_agree_test_map {
    background: url(/static/media/verifier.ea4f0b17.svg) no-repeat;
    background-position-x: 0px;
    background-position-y: 0px;
    background-size: 20px;

}
.container_icon_LOINC{
  width: 20px;
  height: 20px;
}

.commun_map{
  padding-top: 2px;
padding-bottom: 2px;
}


.goldclient {
  background-color: #ebe536;

}


.nocode {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
}
.nocode img {
  width: 250px;
}
.nocode_text{
  font-weight: bold;
  font-size: 20px;
}


.croix{
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.outloinc{
  background-color: #9CB074;
}


.outloinc_nt{
  background-color: #aaa8aa55;
}
.outloinc_card{
  display: flex;
  flex-direction: column;
  min-height: 300px;
}


.outloinc_card_center{
 justify-content: center;
 
}

.nothing{
flex: 1 1 50px;

}

.duplicate_margin{
  margin-right: 15px;
}


.icone_duplicate{
  transition: transform 0.2s;
}

.icone_duplicate:hover{
  transform: scale(1.3);
}

.add_container{
  height: 400px;
  display: flex;
  justify-content: center;
}


.addLoinc_container{
  display: flex;
  height: 100%;
  justify-content: center;
  flex: 1 1;
}

.addLoinc {
  background: url(/static/media/ajouter.85b0cc68.png) no-repeat;
  width: 100px;
  height: 100px;
  background-size: 100px;
  margin-top: auto;
  margin-bottom: auto;

}

.addLoinc:hover {
  background: url(/static/media/ajouter_hover.eacf6816.png) no-repeat;
  background-size: 100px;

}


.btn_search_container{
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: left;
}



.h_no_bottom{
  margin-bottom: 0px;
}
.button {
    margin: 0px 20px;
    padding: 10px 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4db6eb;
    border: none;
    border-radius: 40px;
    box-shadow: 0 6px #999;
}

.button:hover {
    background-color: #099feb;
}
.button:active {
    background-color: #f5b23d;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
}

.auditGreen {
  background-color: #28a745;
}

.auditGreen:hover{
  background-color: #085619;
}

.auditYellow{
  background-color: #ffe507;
}

.auditYellow:hover{
  background-color: #ffc107;
}

.auditOrange{
  background-color: #fd7e14;
}

.auditOrange:hover{
  background-color: #c98727;
}

.auditRed{
  background-color: #dc3545;
}



.auditRed:hover{
  background-color: #7a121c;
}

.auditBlack{
  background-color: #333333;
}



.auditBlack:hover{
  background-color: #000000;
}


.searchOn{
  background-color: #333333;
}

.searchOn:hover{
  background-color: #000000;
}

.searchOnly{
  background-color: #ff0000;
}

.searchOnly:hover{
  background-color: #970202;
}


.btn_search{
  width: auto;
  font-size: 16px;
  margin:10px;
}

.dashbutton{
  font-size: 12px;
  /* margin:5%; */
}


.labo_account_btn{
  background-color: #1A385E;
  margin-top:2%;

}
.labo_account_btn:hover{
  background-color: #132945;

}

.btn_color_search{
  background-color: #1A385E;
}

.btn_color_search:hover{
  background-color: #132945;
}



.btn_page_next{
  width: 30px;
  height: 30px;
  padding: 0px;
  border-radius: 50%;
}

.btn_page_prev{
  width: 30px;
  height: 30px;
  padding: 0px;
  border-radius: 50%;
  margin-right: 0px;
}

/**************/
/**NAV BUTTON**/
/**************/
.navbutton {
  margin: 0px 20px;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #099feb;
  border: none;
  border-radius: 40px;
  box-shadow: 0 6px #999;
}

.navbutton:hover {
  background-color: #045c88;
}

.navbutton:active {
  background-color: #f5b23d;
  box-shadow: 0 3px #666;
  transform: translateY(4px);
}


.relatifNav{
  width: 30px;
  height: 30px;
  padding: 0px;
}


.btn_margin{
  margin-bottom: 10px;
}

.validation_btn{
  background-color: #099feb;
  width:200px;
}


.validation_btn:hover{
  background-color: #045c88;
}


.changer_dico_btn{
  background-color: #099feb;
  width:280px;
}


.changer_dico_btn:hover{
  background-color: #045c88;
}





.button_end{
  width:60%;
  margin-left: auto;
  margin-right: auto;
}


.btn_dashboard{
  height: 40px;
  padding: 0;
  margin: 0;
  width: 150px;
  margin-left: 20px;
  margin-right: 30px;
  background-color: #1A385E;
}

.btn_dashboard:hover{
  background-color: #132945;

}


.anomalieColorOff{
  background-color: #616685;
}

.anomalieColorOff:hover{
  background-color: #545A85;
}

.anomalieColor{
  background-color: #dc3545;
}


.anomalieColor:hover{
  background-color: #7a121c;
}


.btn_tuto{
  background-color: #1A385E ;
  width: 90px;
  height: 30px;
  padding: 0;
  font-size: 15px;
}

.btn_tuto:hover{
  background-color: #F2CB88;
}

.dashclientbutton{
  margin:0;
}

.shortcut{
  background-color: #4f4f4f;
}

.shortcut:hover{
  background-color: #272626;
}


.btn_modif_dico{
  font-size: 14px;
  padding: 5px 11px;
  border-radius: 20px;
}


.btn_questionnaire_prev{
  background-color: white;
  color: #1A385E;
  font-size: 16px;
  padding: 8px 20px;
}

.btn_questionnaire_prev:hover{
  background-color: rgb(163, 162, 162);
}

.btn_questionnaire{
  background-color: #1A385E;
  font-size: 16px;
  padding: 8px 20px;
}



.btn_questionnaire:hover{
  background-color: #0e1b2c;
}


.upload_btn{
  width: 100px;
  height: 30px;
  font-size: 16px;
  padding: 0px;
}


.upload_btn_technidata{
  width: 150px;
  height: 30px;
  font-size: 16px;
  padding: 0px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position:fixed;
  background: white;
  width: 30%;
  top:50%;
  left:50%;
  color: black;
  transform: translate(-50%,-50%);
  border-radius: 25px;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
  padding: 30px;
}

.modal-position{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-position button {
  margin: 0px 20px;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4db6eb;
  border: none;
  border-radius: 40px;
  box-shadow: 0 6px #999;
}

.modal-position button:hover {
  color: #fff;
  background-color: #00679b;
}

.modal-position button:active {
  background-color: #f5b23d;
  box-shadow: 0 3px #666;
  transform: translateY(4px);
}

.modal-label{
  /*display: flex;
  flex-direction: column;*/
  font-size: 20px;
}

.modal-label input {
  box-sizing: border-box;
  outline: none;
  border: 2px solid #1189de;
  border-radius: 4px;
  color: #292929;
  width: 100%;
  margin-top: 15px;
  padding: 12px;
  font-size: 18px;
  background: #fff;
  border-radius: 25px;
}

.modal-btn{
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-top: 10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.error {
  color:red;
  margin-top:20px
}

.container_info_div_LOINC{

  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -12px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.container_icon_LOINC_info{
  width:20px;
  height: 20px;

}


.panel_decalage{
  font-size:14px;
  margin-left:30px;
}

.container_endform{
    /*ackground: url(../../img/background_mapper.png) no-repeat;
    background-size: cover;*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
}


.endform_position_card {
  width: 40%;
  border-style: none;
  border-radius: 25px;
  box-shadow: 0 5px 14px rgba(0,0,0,.15);
  padding: 10px;
  background-color: #fff;
  height: 350px; /*175px;*/
  display: flex;
  flex-direction: column;
  margin:2%;
  justify-content: space-around;
  padding: 20px;
}

.endform_position_card_hight{
  height: 500px; 
  padding-top: 30px;
}

.endform_position_card_large{
  width: 50%;
}

.container_end_line{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}



.end_interligne{
  text-align: center;
  line-height: 1.5em;
}

.title_end{
  color: #315D79  ;
  text-align: center;
  margin-bottom: 0;
margin-top: 10px;
}



.container_end_withoutbtn{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  background-color: rgba(255,255,255,0.5);
  border-style: none;
  border-radius: 25px;
  margin-top: 1%;
  margin-bottom: 1%;


}

.container_btn_endform{
  margin-left: auto;
  margin-right: auto;
  margin-top:1%;
  margin-bottom:1%;
}

.endform_roller{
  position: absolute;
  top:40%;
}


.text_download{
  color:#fff;
  font-size: 18px;
}



.base_type_end{
  display: flex;
flex-direction: row;
align-items: center;
justify-content: right;
margin-top: 1%;
width: 100%;
}


.export_center{
  text-align: center;
}
/**************/
/***Bootstrap Progress-bar ****/
/**************/


.progressContainer{
  width :100%;
  margin-right: auto;
  margin-left: auto;
}
/*
@media (min-device-height: 1440px) and (min-height:1300px) {
  .progressContainer{
    width :100%;
    margin-right: auto;
    margin-left: auto;
    position:absolute;
    bottom:0;
  }
}*/

.progressMaster{
  display: flex;
  overflow:hidden;
  line-height: 0;
  font-size: .75rem;
  background-color: #e9ecef;
  height: 35px;
  border-radius: 25px;
}
.progressBar{
  display: flex;
overflow: hidden;
color: #000;
font-size: 15px;
flex-direction: column;
justify-content: center;
text-align: center;
white-space: nowrap;
background-color: #007bff;
transition: width .6s ease;
}
.done{
background-color: #28a745 !important;
}
.warn{
background-color: #ffc107 !important;
}
.dang{
  background-color: #dc3545 !important;

}




.progressMaster::after::before{
  box-sizing: border-box;
}
.legend_progress_bar {
  display: flex;
  justify-content: center;
  align-items: space-around;
  flex-flow: row wrap;
}

.legend_contain{
  display: flex;
  flex-direction: row;
  margin-right: 1%;
  width: 250px;
  justify-content: center;
  align-items: center;
}

.foo {
  float: left;
  width: 20px;
  height: 20px;
  margin: 5px;
  border-style: none;
  border-radius: 5px;
}
.green {
  background: #28a745;
}
.yellow {
  background: #ffc107;
}
.red {
  background: #dc3545;
}



.container_comm_uniq{
  display: flex;
  flex-flow: row ;
  justify-content: space-between;

}



.icone_comm{
  margin-left: 50px;
  transition: transform 0.2s;

}

.icon_multi{
    margin-left: 20px;  
  }

.icone_comm:hover{
  transform: scale(1.3);
}

.icone_multi_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.btn_actions{



}



.btn_validation{


  margin-left: auto;
  margin-right: auto;
  width: auto;
  margin-bottom:10px;

}


.container_final_middle {
  margin: 50px 2% 0px 2%;
  display: flex;
  flex-direction: column;
}



.container_comm_btn{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.container_button_middle{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: auto;
}


.audit_containt{
  width: auto;
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 8px rgba(0,0,0,.2);
  height: auto;
}

.container_client {
  width: 500px;
  padding: 25px 0 10px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
  color: #545b6f;
  text-align: left;
  font-size: 16px;
}

.title_client_search {
  margin-top:0px;
    font-size: 20px;
    text-align: left;
    color: #545b6f;
}
.center {
    text-align: center;
}
/*
.interligne {
    line-height: 1em;
}
.sizing_entree {
    padding-left: 60px;
}
.characters {
    font-weight: bold;
}*/
.title_container_client {
/*    width: 600px;*/
    /* margin-bottom: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.title_container_client h1 {
    margin: 0;
}

.adjut_items_client {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
}

.search_client{
 /* width:auto;*/
  margin: 1%;
  padding:1%;
  height: 100%;
  font-size:12px;

}

.expertbtn{
  padding-bottom: 15px;
  text-align: center;
}


.entry_collapse_div{
  margin-left:20px;

}

.container_onaos .ReactCollapse--collapse{
  transition: height 0.1s linear;

}


.no_top{
  margin-top:0;
}


.green_validation{
  color:green;
  text-align: center;
}

.yellow_pass{
  color: #FF8A05;
  text-align: center;
}


.modal_main_search {
  position:fixed;
  background: white;
  top:2%;
  left:2%;
  color: black;
  border-radius: 25px;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
  padding: 10px;
  width: 96%;
  height: 90%;
}

.modal_position_search{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}


.findloinc_roller{
  position: absolute;
  z-index: 200;
  top: 50%;
  left: 50%;
}




.modal_btn_search{
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-top: 10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}


.container_search{
  display: flex;
  flex-direction: column;
}


.container_title_search{
  display: flex;
  flex-direction: row;

  margin-left:auto;
  margin-right: auto;
  margin-top:0.5%;
  margin-bottom:0.5%;


}

.modal_label_search{
  font-size: 25px;

}

.container_bloc_input{
  display: flex;
  flex-direction: row;

}


.container_bloc_btn{
  width:auto;
  margin-left:auto;
  margin-right: auto;

}

.search_nabm{
  -webkit-text-decoration: underline #000 dashed;
          text-decoration: underline #000 dashed;
}

.search_nabm:hover{
  cursor: help;
}

/*********/
/***MAP***/
/*********/





.container_propositions_map_search {
    display: flex;
  /*  justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;*/
    flex-direction: column;
    width:97%;

    padding: 1%;
    background-color: #d9e8ff;
    color: #545b6f;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

    margin: 1%;


  overflow: auto;
  height: 66vh;

}




.container_propositions_cards_map_search {
    text-align: center;
    padding: 1%;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 300px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
}
.container_propositions_cards_map_search:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
}



.img_agree_test_map {
    background: url(/static/media/verifier.ea4f0b17.svg) no-repeat;
    background-position-x: 0px;
    background-position-y: 0px;
    background-size: 20px;
   z-index: 0;
}

.commun_map{
  padding-top: 2px;
padding-bottom: 2px;
}

.result_center{
  margin-left:auto;
  margin-right: auto;
}

.loincresult_table{
  border-spacing: 0;
    border: 1px solid black;
    background-color: #fff;
    margin-bottom:10px;
       overflow-y: auto;
       width: 100%;
}


tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-size: 12px;
  :last-child {
    border-right: 0;
  }
}


.restreint_cell{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loincresult_body{
  background-color: #fff;
    color:black;
    font-weight: bold;
}

.loincresult_body:hover{
  background-color: rgba(0, 0, 0, .15);

}

.loincresult_unrestreint:hover{
  background-color: rgba(0, 0, 0, .2);
}

.loincresult_unrestreint{
/*  background-color: #E6F5FF;*/
  color : rgba(0, 0, 0, 1);
}


.result_select{
  background-color: #CFFFCC;

}


.result_select:hover{
  background-color: #81B37D;
}

.icone_caution{
  padding-right: 10px;
}


.mouse_unrestreint:hover{
  cursor: help;
}



.tooltip_lines{
  white-space: pre-line;
}


.panelsearch_OuiNon,
.panelsearch_Oui{
  border-radius: 5px;
  padding: 2%;
  background-color: #88eb42;
  color: #fff;
}

.panelsearch_Voir:hover,
.panelsearch_OuiNon:hover,
.panelsearch_Oui:hover{
  box-shadow: 0 0 4px rgba(0,0,0,.3);
  cursor: pointer;
}

.panelsearch_OuiNon{
  background-color: #529E1C;
}


.panelsearch_Non{
  border-radius: 5px;
  padding: 2%;
  background-color: #eb7f59;
  color: #fff;
}


.panelsearch_Voir{
  border-radius: 5px;
  padding: 2%;
  background-color: #5984eb;
  color: #fff;
}


.Obligatoire{
  color: #eb7f59;

}

.margin_left{
  margin-left: 10px;
}

.container_panel_inactif{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.inactif_loinc{
  background-color: #a4ccc4;
}

.boldLOINC{
  font-weight: bold;
}



.container_filter{

}


.title_filter{
  cursor: pointer;
  margin-bottom: 0px;
  padding-left: 10px;
  font-weight: bold;
  font-size: 14px;
}


.information_table{
  margin-left: 4px;
}


.container_information_table{
  display: flex;
  align-items: center;
}

.resultHead{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}



.resultHeadText {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 20px;
}


.blocSelectHead{
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
}


.blocPageHead{
  margin-top: auto;
  margin-bottom: auto;
  font-style: bold;
  font-weight: bold;
  font-size: 20px;
}


.selectHead{
  margin-left: 20px;
  text-align: center;
  width: 80px;
}


.search_input{
  width: 400px;
font-size: 16px;
border-radius: 15px;
border-style: none;
box-shadow: 0 0 15px rgba(0,0,0,0.15);
height: 35px;
padding-left: 10px;
}


.container_search_box{
  display: flex;
flex-direction: row;
}


.search_input_p{
  margin-bottom: 0;
}


.container_input_check{
  display: flex;
  flex-direction: column;
}


.container_btn_search{
  display: flex;
  flex-direction: row;


}

.search_input_check{
  margin-top:10px;
  margin-left:5%;

}


.label_checkbox_search{
  margin-left: 10px;
}

.search_history{
  width: 410px;
 border-top-width: 0;
 list-style: none;
 margin-top: 0;
 max-height: 150px;
 overflow-y: auto;
 position: absolute;
 background-color: #E6F5FF;
 border-radius: 5px;
 padding-left: 0px;

}

.search_history li {
  padding: 0.5rem;
}

.search_history-active,
.search_history li:hover {
  background-color: #8FA4B3;
  color: #e06328;;
  cursor: pointer;
  font-weight: 700;
}

.sidebar {
  position: absolute;
  top: 0px;
  left: -350px;
  height: 100%;
  width: 300px;
  transition: left .3s ease-in-out;

  background-color: #e6e6e6;;
  border-radius: 0 25px;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.15);
  overflow: auto;
  padding-top: 10px;
}

.sidebar.open {
    left: -40px;
}

.dropdown_filter ul {
  list-style: none;
  margin-top:10px;
  font-size: 12px;
}

.text_filter{
  padding-left: 5px;
  color:#e06328;
}

.text_filter:hover{
  color:#1b966b;
}

.filter_value{
  /*  float: right;*/
    padding-right: 25px;
        margin-left: auto;
}

.dropdown_filter ul {
  padding-left: 20px;
}

.dropdown_filter ul li {
  cursor: pointer;
  display: flex;
}

.dropdown_filter ul li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.duplicationvalidation_main{
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
    overflow: auto;
}

.display-block {
  display: block;
}


.duplicationvalidation_containier{

  justify-content: center;
  align-items: center;
  position:fixed;
  background: white;
  max-width: 70%;
  top:50%;
  left:50%;
  color: black;
  transform: translate(-50%,-50%);
  border-radius: 25px;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
  padding: 30px;
  max-height: 650px;
  overflow: auto;
}

.duplicationvalidation_texte{

}

.duplicatevalidation_btn{
  display: flex;
  justify-content: center;
}


.duplication_value{
  overflow: auto;
  max-height: 450px;
  background-color: #ffffff;
  margin-top:1%;
  margin-bottom:1%;
  padding-top: 1%;
}



.container_check_txt{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  margin-left:5px;
}


.checkboxandtxt{
  width: 100%;
  margin-bottom: 1%;
  display: flex;
flex-direction: row;
justify-content: space-between;
}

.container_check_txt .checkboxandtxt{
  border-bottom: 1px dashed;
  padding-bottom: 5px;
}


.inputdisabled{
  color: gray;
}


.allselect{
  justify-content: right;
  margin-right: 16px;
  margin-bottom: 0px;
}

.keyboard_main{
    display: block;
    position: fixed;
    top: 25%;
    left: 25%;
    width:50%;
    height: 50%;
    z-index: 10;

}


.keyboard_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width:70%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(96, 100, 108, 0.8);
    border-radius: 10px;
    box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
}


.shortcut_img{
    width: 24px;
    height: 24px;
    margin-right: 50px;
}

.shortcut_line p{
    text-align: left;
}

.shortcut_line{
    width:80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color : rgb(235, 226, 226);
    font-weight: bold;
}


.shortcut_bouton_container{
    margin-top: 20px;
    margin-bottom: 4%;
    margin-left: auto;
    margin-right: auto;
    
}


.shortcut_img_lbl{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.shortcut_title{
    margin-left: auto;
    margin-right: auto;
    -webkit-text-decoration: white underline;
            text-decoration: white underline;
  }
.container_quesitonnaire_btn{
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-around;
}

.label_radio_questionnaire{
    display: flex;
    flex-direction: column;
}



.radio_button_control{
    font-size:14px;
}

.error_questionaire{
    color:red;
}
.input_questionnaire{
    width: 90%;
height: 80%;
margin-left: auto;
margin-right: auto;
border-radius: 10px;
border-style: none;
}



.container_text_questionnaire{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
}

.text_p_questionnaire{
    font-size: 16px;
        width: 96%;
    margin-left: auto;
    margin-right: auto;
}
.questionnaire_container_btn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top:10px;
    padding-bottom: 10px;
}


.questionnaire_nombre{
    font-size: 16px;
    font-weight: bold;
}
.container_main_question{
    display:flex;
    flex-direction: row;
}


.question_leftside{
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}


.container_question{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(/static/media/background_uni.50501904.png) no-repeat;
    background-size: cover;
    font-family: "Montserrat", sans-serif;
  background-color: #4db6eb;
}


.question_img{
    width: 50%;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}


.question_rightside{
    display: flex;
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 85%;
    background-color: rgba(255,255,255,0.5);
    border-style: none;
    border-radius: 15px;
    margin-top: 1%;
    margin-bottom: 1%;
    min-height: 45%;
    padding-top:10px;
    padding: 20px;
}

.base_type_end{
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-top: 1%;
  width: 100%;
  }


  .logo_questionnaire{
    position: absolute;
    top: 0;
    right: 0;
  }

  .questionnaire{
      font-size: 16px;
  }

  .questionnaire_page{
    text-align: center;
    padding: 20px;
  }


.choixform{
    background-size: cover;
    font-family: "Montserrat", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    height: 100vh;
    margin-top: -100px;
  /*
    background: url(../../img/background_mapper.png) no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;*/
}


.choixform_position {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    border-style: none;
    border-radius: 25px;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    padding: 20px;
    background-color: #fff;
    max-height: 650px;
}

.choixform_position p {
    font-size: 16px;
    text-align: center;

}

.choixform_container{
      text-align: center;
}

.select_style{
  display: flex;
  margin:1%;
  justify-content: 'center';
  align-items: center;
}
.bold{
  font-weight: bold;
}

.centerselect {
    text-align: center;
    width:100%;
    margin-left:auto;
    margin-right: auto;
}

.choixform_disposition {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    width: 100%;
    height: 50px;
}

.choixform_disposition_line{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    justify-content: center;
}


.base_type_connection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 15px;
}

.logo_onaos_mapping_connection{
    width: 7%;
    margin-right: 30px;
}


.base_type_choice{
    justify-content: right;
}
#root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

.dash_background{
/*    background: url(../../../img/background_mapper.png) no-repeat;
    background-size: cover;*/
  /*  height: 100vh;*/
    display: flex;
   flex-direction: column;
    justify-content: center;

}


.dash_navigation{
  margin-left: auto;
  margin-right: auto;
  padding-bottom : 0%;
}


.axonaute_roller{
  position: absolute;
  z-index: 200;
  top: 15%;
  left: 50%;
}


.dash_container_client{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  padding: 30px;
}


.dash_client {
  text-align: center;
  padding: 1%;
  margin-right: 20px;
  margin-bottom: 20px;
 /* width: 25%;*/
  width:10%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  height: 130px;
 /* height: 200px;*/

}

.dash_client_big{
  height: 200px;
  width: 25%;
}

.dash_client:hover {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  transform: scale(1.03);
}


.dash_clientactions {
  text-align: center;
  padding: 1%;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 200px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.dash_clientactions:hover {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  transform: scale(1.03);
}



.container_client_dico{

  height: 150px;
  overflow: auto;

}


.formation_container{
  display: flex;
flex-direction: column;
justify-content: space-between;
width: 15%;
}


.redreview{
  color: red;
  font-weight: bold;
}

.greenreview{
  color: green;
  font-weight: bold;
}



.boldreview{
  font-weight: bold;
}



.dash_container_client_all{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
}

.dash_container_client_action{
  display: flex;
  justify-content: center;
  flex-direction : row ;


  height: auto;
  padding: 30px;


}


.dash_container_client_info{

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dash_client_info{
  text-align: center;
  padding: 1%;
  margin: 20px;
  width: 500px;
  height: auto;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

}


.dash_client_actions {
  text-align: center;
  padding: 1%;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 240px;

  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

}

.dash_client_actions_actif{
  background-color: #fff;
  transition: transform 0.2s;
}

.dash_client_actions_unactif{
  background-color: #DDD;

}

.dash_client_actions_actif:hover {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  transform: scale(1.03);
}


.review_label{
  color : red;
}

.review_client_label{
  color : green;
}



.chapitre_tooltip{
  max-width: 80%;
  white-space: pre-wrap;
}

.dash_container_client_pretrait{
  display: flex;
  flex-direction: row;

  width:96%;
  height: 80vh;
  padding: 10px;


}



.main_client_pretrait{
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1%; /*2%*/
  margin-right: auto;
  margin-left: auto;
  /*width: 70%;*/
  width: 78%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

  overflow: auto;
  height: 90vh;
  transition: left 0.3s ease-in-out;
  position: fixed;
  left:17%;
}

.main_client_pretrait_full{
  width: 96%;
  left: 1%;
}

.dash_client_axtions_pretrait_container{
  display: flex;
  flex-direction: column;
  height: 600px;
  justify-content: space-between;
  width: 15%;
  position: fixed;
  left:1%;
  transition: left 0.3s ease-in-out;
  top:50px;


}

.menu_dash_pretrait{
  position: absolute;
  top:0;
  z-index: 100;
  font-weight:bold;
}

.dash_client_axtions_pretrait_container.hide_dash_menu{
  position:fixed;
  left: -300px;

}

.dash_client_actions_pretrait {

  text-align: center;
  padding: 1%;
  margin-right: auto;
  margin-bottom: auto;
  /*width: 14%;300px*/
  height: 400px;/*300px;*/
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: normal;

}

.dash_navigation_pretrait{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2%;
  height: 100px;
  justify-content: space-between;
}

.dash_btn_container{
  height: 97%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5%;
  padding-top: 5%;
}


.container_render p{
    font-size: 14px;
}


.container_checklist{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:1%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top:2%;

}


.basefind{
  font-weight: bold;


}



.margin50{
  margin-top:100px;
}


.check_container{
  margin-left:auto;
  margin-right: auto;
}


.dashboard_icone_open{
  background: url(/static/media/burger_menu_button.535c4e43.svg) no-repeat;
  width:35px;
  height:35px;
}

.dashboard_icone_open:hover{
  background: url(/static/media/burger_menu_button_hover.d420d0bc.svg) no-repeat;
}

.dashboard_icone_open:active {
  background: url(/static/media/burger_menu_button_clic.6f49808a.svg) no-repeat;
  transform: translate(4px,4px);
}

.dashboard_icone_close{
  background: url(/static/media/burger_menu_close_button.f97693de.svg) no-repeat;
  width:35px;
  height:35px;
}

.dashboard_icone_close:hover{
  background: url(/static/media/burger_menu_close_button_hover.b0b67006.svg) no-repeat;
}

.dashboard_icone_close:active {
  background: url(/static/media/burger_menu_close_button_clic.5a3dbda3.svg) no-repeat;
  transform: translate(4px,4px);
}



.dash_nav{
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  padding-bottom : 0%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto
}



.container_select_txt{

  display: flex;
  flex-direction: row;
  justify-content: space-between;

}


.container_dicotable{
/*  width: 80%;*/
  margin-left: auto;
  margin-right: auto;
}

.container_dicotable_infinite{
/*  width: 80%;*/
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  overflow: auto ;
}


.sitcky{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
}

.index_scroll{
  z-index: 100;
}

.dico_table{
  border-spacing: 0;
  border: 1px solid black;
  background-color: #fff;
  /*margin-bottom:10px;*/
  overflow-y: auto;
  width: 100%;
  margin:1%;

}


.dico_table_sticky{
  border-spacing: 0;
  border: 1px solid black;
  border-top:0;
  background-color: #fff;
  /*margin-bottom:10px;*/
  overflow-y: auto;
  width: 100%;

}

tr:last-child.td {
  border-bottom: 0;
}

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-size: 12px;
}

td:last-child{
  border-right: 0;
}

.dico_table_sticky th {
  border-top : 1px solid black;
}

.select_table{
  padding-bottom: 10px;
}


.container_select_pre{
  display: flex;
  flex-direction: column;
}

.select_add{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}


.select_pre{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}


.dash_nav_page{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-bottom : 0%;
  justify-content: center;


}

.addorremove_supp_expert , .addorremove_add_expert{

}




.addorremove_supp_button {
    background: url(/static/media/supp_button.ea9f3237.svg) no-repeat;
    width: 20px;
    height: 20px;
    background-size: cover;

}

.addorremove_supp_button:hover {
    background: url(/static/media/supp_button_hover.cc8b2dff.svg) no-repeat;
    background-size: cover;
}

.addorremove_supp_button:active {
    background: url(/static/media/supp_button_clic.49a54139.svg) no-repeat;
    transform: translate(-2px, 0px);
    background-size: cover;
    width: 16px;
    height: 16px;
}



.addorremove_add_button{
  background: url(/static/media/add_button.7e96d08e.svg) no-repeat;
  width:20px;
  height: 20px;
  background-size: cover;
}

.addorremove_add_button:hover{
  background: url(/static/media/add_button_hover.22c62259.svg) no-repeat;
  background-size: cover;
}

.addorremove_add_button:active {
  background: url(/static/media/add_button_clic.c699a4ea.svg) no-repeat;
  transform: translate(-2px, 0px);
  background-size: cover;
  width: 16px;
  height: 16px;
}

.container_compterendu p{
  font-size: 10;
}

.pdf{
    display: flex;
    flex-direction: column;
    width: 1170px;
    margin-left: auto;
    margin-right: auto;
    }
  
  .container_head, .container_middle, .container_foot {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 200px;
  }

  .container_foot{
    flex-direction: column;

  }

  .container_infoclient, .container_date, .pdf_totalval,.pdf_gold, .pdf_repartition, .pdf_leftdonut, .pdf_rightdonut{
    width: 50%;
  }

 .pdf_gold{
    margin-left: auto;
    margin-right: auto;
 }


 .pdf_p{
  margin-left: auto;
  margin-right: auto;
 }

 .pdf_row{
   display: flex;
   flex-direction: row;
   width: 100%;
 }


 .head_piechart{
   margin-bottom: -80px;
 }

.dash_container_account{
  display: flex;
  flex-flow: row wrap;
  padding: 30px;
}


.dash_account {
  text-align: center;
  padding: 1%;
  margin-right: auto;
  margin-left: auto;
  width: 60%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  min-height: 600px;
}

.dash_account_input{
  margin:1%;
}

.dash_validation{
  margin-top:3%;
}

.dico_modal {
  position:fixed;
  background: white;
  top:2%;
  left:2%;
  color: black;
  border-radius: 25px;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
  padding: 10px;
  width: 96%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}


.display-block {
  display: block;
}

.display-none {
  display: none;
}

.dico_nav{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3%;
}



.container_account_gestion{
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2%;
  margin-right: auto;
  margin-left: auto;
  width: 65%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  min-height: 800px;
  margin-top:3%;
  margin-bottom:3%;
  overflow: auto;
}


.labo_gestion{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}


.ReactCollapse--collapse {
  transition: height 500ms;
}


.expert_gestion{
  border: 2px solid black;
  border-radius: 15px;
  padding:1%;
  margin-bottom: 10px;

}

.expert_gestion_btn{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.main_expert{
  margin-top : 20px;
}




.expert_statut{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}


.bouton_container_test{
  margin-left: auto;
  margin-right: auto;
  margin-top:20%;
}


.technidata{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

}
.container_import_files{
  width: 90%;
  margin-left: auto;
  margin-right: auto;

}



.table_import_files{
  border-spacing: 0;
    background-color: #fff;
    margin-bottom:10px;
       overflow-y: auto;
       width: 100%;
           border: 1px solid black;

}

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-size: 12px;

}


tr:last-child td{
  border-bottom: 0;

}

tr td:last-child{
  border-right: 0;
}

tr th:last-child{
  border-right: 0;
}


.input_import{
  height: 30px;
  width: 200px;
}


.trash_import{
  height: 30px;
  width: 30px;
}



.trash_import:hover{
  transform: scale(1.15);
}


.error_input{
  border: 2px solid red;
}


.select_input{
  width: 200px;
  height:30px;
  text-align: center;

}

.error_select{
  border: 2px solid red;
  width: 200px;
  height: 38px;
}

.td_select_import{
  display: flex;
  justify-content: center;
  height: 36px;
}


.container_account{
  display: flex;
  flex-direction: row;
}

.container_title_onaos{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.title_onaos{
  width: 150px;
  margin: 10px 25px 0 0;
  z-index: 100;
}

.labo_img{
  width: 50%;
}

.labo_img_container{
  width: 50%;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.labo_account{
  background-color: #4cb4ec;
  width: 50%;
  height: 100vh;
  margin-left:50%;
  position: fixed;
  overflow: auto;
}

.labo_account_main_container{
  margin:auto;
  padding: 20px;
  text-align: center;
}

.account_title{
  text-align: center;
  color: white;
  font-size: 30px;
}

.add_expert {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  padding:2%
}

.add_expert p {
  margin: 10px;
}

.add_button{
  background: url(/static/media/add_button.7e96d08e.svg) no-repeat;
  width:35px;
}

.add_button:hover{
  background: url(/static/media/add_button_hover.22c62259.svg) no-repeat;
}

.add_button:active {
  background: url(/static/media/add_button_clic.c699a4ea.svg) no-repeat;
  transform: translate(4px,4px);
}

input:focus {
    outline: none !important;
}

.account_input_container{
  display: flex;
  flex-direction: row;
  margin: 0 auto 25px auto;
  justify-content: center;
}

.account_input_full{
  width : 45%;
  font-size: 16px;
  border-radius: 15px;
  border-style: none;
  box-shadow: 0 0 15px rgba(0,0,0,0.15);
  height: 40px;
  padding-left: 10px;
}

.account_input_multiple_container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width : 46%;
}


.account_input_multiple{

  font-size: 16px;
  border-radius: 15px;
  border-style: none;
  box-shadow: 0 0 15px rgba(0,0,0,0.15);
  height: 40px;
  padding-left: 10px;

}


.input_error{
  border : 3px solid #EB6572;
}

.supp_expert {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

.supp_button {
    background: url(/static/media/supp_button.ea9f3237.svg) no-repeat;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 10px;

}

.supp_button:hover {
    background: url(/static/media/supp_button_hover.cc8b2dff.svg) no-repeat;
}

.supp_button:active {
    background: url(/static/media/supp_button_clic.49a54139.svg) no-repeat;
    transform: translate(4px, 4px);
}





.checkbox_container{
  width: 40%;
  margin-left: auto;
  margin-right: auto;

}



._expert_account_title{
  text-align: center;
  color: white;
  font-size: 30px;
  margin-bottom:15px;
}

.account_checkbox_label {
  display: flex;
  flex-direction: row;
  margin: 5px auto;
  justify-content: space-between;
  width: 350px;
  font-size: 16px;
  font-weight: 550;
  color:#1A385E;
}


.account_checkbox_container{
  display: flex;
  flex-direction: row;
  margin: 5px auto;
  justify-content: center;
}


.account_checkbox_label_red{
  color: #EB6572 ;
}


.white_size{
  color:#fff;
  text-align: left;
  font-size: 14px;
}

.chapitre_container{
  width: 42%;
  margin:auto;
  padding-bottom: 2%;
}


.chapitre_title{
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: justify;
}


.multiselect_container{
  border-radius: 8px;
}



.multiselect_container_red{
    border : 3px solid #EB6572 ;
  }

.container_invalid_page{
  width: 100%;
  height: 100vh;
  background: url(/static/media/background_uni.50501904.png) no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4db6eb;

}

.invalid_img{
  margin-right: 50px;
  border-radius: 15px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 30%);
}

.container_txt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #1A385E;
}

.container_txt h1 {
  font-size: 50px;
}


.container_return_validation_invalid{
  width: 100%;
  padding-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.container_password{
  display: flex;
  flex-direction: row;
}

.expert__account{
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4db6eb;
}
.modif_pswd_title {
  text-align: center;
  color: #1A385E;
  font-size: 25px;
  margin: 5px;
}

.font_color{
  color: #1A385E;
}
.logoRecupMail {
  width: 200px;
  margin-bottom: 10px;
}
.container_main_email{
  display:flex;
  flex-direction: row;
}
.container_msg{
  text-align: center;
  margin-top: 50px;
  min-height: 20px;
}
.container_msg p {
  color: #fff;
  font-size: 12px;
}
.recup_title {
  font-size: 24px;
  margin: 0;
}
.container_upperbox {
  width: 79%;
}
.upperbox {
  text-align: left;
  font-size: 14px;
  margin: 0;
}
.underbox {
  font-size: 14px;
  text-align: center;
}

.underboxbold {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}

.container_password_not_received p {
  display: inline;
  margin-right: 5px;
  font-size: 14px;
}
.container_password_not_received a {
  font-size: 14px;
  outline: none;
  text-decoration: none;
  font-weight: bold;
}
.container_return_connection {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.input_size_recup {
width: 25rem;
margin-top: 15px;
padding: 12px;
box-sizing: border-box;
outline: none;
font-size: 14px;
background: #fff;
border-radius: 15px;
border: none;
box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.return_button {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  background: url(/static/media/return_button.a1f72fde.svg) no-repeat;
}
.return_button:hover {
  background: url(/static/media/return_button_hover.da1a5fd2.svg) no-repeat;
}
.return_button:active {
  background: url(/static/media/return_button_clic.260fdb18.svg) no-repeat;
  transform: translate(4px, 4px);
}
.recupForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.containerRecupForm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.recupMailContainer {
    width: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #4db6eb;
}


.fake_link{
  font-weight: bold;
}

.fake_link:hover{
  font-weight: bold;
  color:red;
  cursor: pointer;
}

.containerSideValidation{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.leftsideValidation {
    width: 50%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rightsideValidation {
    width: 50%;
    height: 100vh;
    background-color: #4DB6EB;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.rsvtxt {
    width: 80%;
    margin-top: 0;
    text-align: center;
}
.imgAccountValidation {
    width: 70%;
}
.container_return_validation {
    width: 100%;
    padding-top: 50px;
    padding-right: 200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}
.return_button_validation {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    background: url(/static/media/return_button.a1f72fde.svg) no-repeat;
}
.return_button_validation:hover {
    background: url(/static/media/return_button_hover.da1a5fd2.svg) no-repeat;
}
.return_button_validation:active {
    background: url(/static/media/return_button_clic.260fdb18.svg) no-repeat;
    transform: translate(4px, 4px);
}

.container_tuto {
    width: 100%;
    height: 100vh;
    background-color: #4DB6EB;
}
.container_page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exit_tuto {
   padding: 10px;
   background-color: #fff;
   border-radius: 25px;
   border-style: none;
   font-family: 'Montserrat';
   font-weight: 500;
}
.container_global_button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container_button {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.container_left_button {
    margin-left: 100px;
    padding: 7px 5px 5px 5px;
    background-color: #fff;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.container_right_button {
    margin-right: 20px;
    padding: 7px 5px 5px 5px;
    background-color: #fff;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.prec {
    margin: 0 20px 5px 0;
}
.next {
    margin: 0 5px 5px 15px;
}
.title_nav {
    margin-right: 20px;
    margin-top: 15px;
}
.left_side_button {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    background: url(/static/media/return_button.a1f72fde.svg) no-repeat;
}
.left_side_button:hover {
    background: url(/static/media/return_button_hover.da1a5fd2.svg) no-repeat;
}
.left_side_button:active {
    background: url(/static/media/return_button_clic.260fdb18.svg) no-repeat;
    transform: translate(4px, 4px);
}
.right_side_button {
    width: 40px;
    height: 40px;
    background: url(/static/media/right_button.8c4d0e5f.svg) no-repeat;
}
.right_side_button:hover {
    background: url(/static/media/right_button_hover.fbb120e2.svg) no-repeat;
}
.rigth_side_button:active {
    background: url(/static/media/right_button_clic.b1a6071c.svg) no-repeat;
    transform: translate(4px, 4px);
}
.exit_tuto {
    position: absolute;
    top: 0;
    right: 0;
    margin: 25px 50px 0 0;
}

.exit_tuto:hover{
    font-weight: bold;
}

.maintenance{
  width: 60%;



}


.container_maintenance{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}


.container_maintenance p{
  font-size: 20px;
  font-weight: bold;
}


.maintenance_msg{
  font-size: 20px;
  font-weight: normal;
}
