.container_tuto {
    width: 100%;
    height: 100vh;
    background-color: #4DB6EB;
}
.container_page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exit_tuto {
   padding: 10px;
   background-color: #fff;
   border-radius: 25px;
   border-style: none;
   font-family: 'Montserrat';
   font-weight: 500;
}