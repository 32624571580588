
.interligne {
    line-height: 1em;
}
.sizing_entree {
    padding-left: 40px;
}
.characters {
    font-weight: bold;
}


.alignement {
    text-align: left;
    margin-left: 20px;
}


.sizing_entree_search {
    padding-left: 10px;
    font-size: 12px;
}

.sizing_title_search {
    font-size: 24px;
}

.tooltip {
    text-align: left;
    margin-left: 2px;
    font-size: 12px;
}

.icone_caution_map{
  margin-left: 10px;
  margin-bottom: -1px;
      transition: transform 0.2s;
}

.icone_caution_map:hover{
  transform: scale(1.03);

}

.inside{
  padding: 0 !important;
margin: 0 !important;
}

.panel_label{
  cursor: pointer;
}


.panel_Oui{
  border-radius: 5px;
  padding: 1%;
  background-color: #88eb42;
  color: #fff;
}


.panel_Oui:hover{
  box-shadow: 0 0 4px rgba(0,0,0,.3);
  cursor: pointer;
}

.panel_Non{
  border-radius: 5px;
  padding: 1%;
  background-color: #eb7f59;
  color: #fff;
}


.restreint_green{
  color:#509f17;
}


.info_tooltip{

  width: 80%;
  /*white-space: pre-wrap;*/
  z-index: 100;
}