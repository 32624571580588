.container_client {
  width: 500px;
  padding: 25px 0 10px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
  color: #545b6f;
  text-align: left;
  font-size: 16px;
}

.title_client_search {
  margin-top:0px;
    font-size: 20px;
    text-align: left;
    color: #545b6f;
}
.center {
    text-align: center;
}
/*
.interligne {
    line-height: 1em;
}
.sizing_entree {
    padding-left: 60px;
}
.characters {
    font-weight: bold;
}*/
.title_container_client {
/*    width: 600px;*/
    /* margin-bottom: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.title_container_client h1 {
    margin: 0;
}

.adjut_items_client {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
}

.search_client{
 /* width:auto;*/
  margin: 1%;
  padding:1%;
  height: 100%;
  font-size:12px;

}

.expertbtn{
  padding-bottom: 15px;
  text-align: center;
}


.entry_collapse_div{
  margin-left:20px;

}

.container_onaos .ReactCollapse--collapse{
  transition: height 0.1s linear;

}


.no_top{
  margin-top:0;
}


.green_validation{
  color:green;
  text-align: center;
}

.yellow_pass{
  color: #FF8A05;
  text-align: center;
}