
.dash_container_client_pretrait{
  display: flex;
  flex-direction: row;

  width:96%;
  height: 80vh;
  padding: 10px;


}



.main_client_pretrait{
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1%; /*2%*/
  margin-right: auto;
  margin-left: auto;
  /*width: 70%;*/
  width: 78%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

  overflow: auto;
  height: 90vh;
  transition: left 0.3s ease-in-out;
  position: fixed;
  left:17%;
}

.main_client_pretrait_full{
  width: 96%;
  left: 1%;
}

.dash_client_axtions_pretrait_container{
  display: flex;
  flex-direction: column;
  height: 600px;
  justify-content: space-between;
  width: 15%;
  position: fixed;
  left:1%;
  transition: left 0.3s ease-in-out;
  top:50px;


}

.menu_dash_pretrait{
  position: absolute;
  top:0;
  z-index: 100;
  font-weight:bold;
}

.dash_client_axtions_pretrait_container.hide_dash_menu{
  position:fixed;
  left: -300px;

}

.dash_client_actions_pretrait {

  text-align: center;
  padding: 1%;
  margin-right: auto;
  margin-bottom: auto;
  /*width: 14%;300px*/
  height: 400px;/*300px;*/
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: normal;

}

.dash_navigation_pretrait{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2%;
  height: 100px;
  justify-content: space-between;
}

.dash_btn_container{
  height: 97%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5%;
  padding-top: 5%;
}


.container_render p{
    font-size: 14px;
}


.container_checklist{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:1%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top:2%;

}


.basefind{
  font-weight: bold;


}



.margin50{
  margin-top:100px;
}


.check_container{
  margin-left:auto;
  margin-right: auto;
}


.dashboard_icone_open{
  background: url('/src/img/menudashboard/burger_menu_button.svg') no-repeat;
  width:35px;
  height:35px;
}

.dashboard_icone_open:hover{
  background: url('/src/img/menudashboard/burger_menu_button_hover.svg') no-repeat;
}

.dashboard_icone_open:active {
  background: url('/src/img/menudashboard/burger_menu_button_clic.svg') no-repeat;
  transform: translate(4px,4px);
}

.dashboard_icone_close{
  background: url('/src/img/menudashboard/burger_menu_close_button.svg') no-repeat;
  width:35px;
  height:35px;
}

.dashboard_icone_close:hover{
  background: url('/src/img/menudashboard/burger_menu_close_button_hover.svg') no-repeat;
}

.dashboard_icone_close:active {
  background: url('/src/img/menudashboard/burger_menu_close_button_clic.svg') no-repeat;
  transform: translate(4px,4px);
}



.dash_nav{
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  padding-bottom : 0%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto
}


