
.search_input{
  width: 400px;
font-size: 16px;
border-radius: 15px;
border-style: none;
box-shadow: 0 0 15px rgba(0,0,0,0.15);
height: 35px;
padding-left: 10px;
}


.container_search_box{
  display: flex;
flex-direction: row;
}


.search_input_p{
  margin-bottom: 0;
}


.container_input_check{
  display: flex;
  flex-direction: column;
}


.container_btn_search{
  display: flex;
  flex-direction: row;


}

.search_input_check{
  margin-top:10px;
  margin-left:5%;

}


.label_checkbox_search{
  margin-left: 10px;
}