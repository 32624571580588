.containerSideValidation{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.leftsideValidation {
    width: 50%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rightsideValidation {
    width: 50%;
    height: 100vh;
    background-color: #4DB6EB;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.rsvtxt {
    width: 80%;
    margin-top: 0;
    text-align: center;
}
.imgAccountValidation {
    width: 70%;
}
.container_return_validation {
    width: 100%;
    padding-top: 50px;
    padding-right: 200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}
.return_button_validation {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    background: url('/src/img/return_button.svg') no-repeat;
}
.return_button_validation:hover {
    background: url('/src/img/return_button_hover.svg') no-repeat;
}
.return_button_validation:active {
    background: url('/src/img/return_button_clic.svg') no-repeat;
    transform: translate(4px, 4px);
}
