.resultHead{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}



.resultHeadText {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 20px;
}


.blocSelectHead{
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
}


.blocPageHead{
  margin-top: auto;
  margin-bottom: auto;
  font-style: bold;
  font-weight: bold;
  font-size: 20px;
}


.selectHead{
  margin-left: 20px;
  text-align: center;
  width: 80px;
}
