.expert__account{
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4db6eb;
}
.modif_pswd_title {
  text-align: center;
  color: #1A385E;
  font-size: 25px;
  margin: 5px;
}
