

.container_account_gestion{
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2%;
  margin-right: auto;
  margin-left: auto;
  width: 65%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  min-height: 800px;
  margin-top:3%;
  margin-bottom:3%;
  overflow: auto;
}


.labo_gestion{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}


.ReactCollapse--collapse {
  transition: height 500ms;
}


.expert_gestion{
  border: 2px solid black;
  border-radius: 15px;
  padding:1%;
  margin-bottom: 10px;

}

.expert_gestion_btn{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.main_expert{
  margin-top : 20px;
}




.expert_statut{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}


.bouton_container_test{
  margin-left: auto;
  margin-right: auto;
  margin-top:20%;
}


.technidata{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

}