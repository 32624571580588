.sidebar {
  position: absolute;
  top: 0px;
  left: -350px;
  height: 100%;
  width: 300px;
  transition: left .3s ease-in-out;

  background-color: #e6e6e6;;
  border-radius: 0 25px;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.15);
  overflow: auto;
  padding-top: 10px;
}

.sidebar.open {
    left: -40px;
}
