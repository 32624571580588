.container_global_button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container_button {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.container_left_button {
    margin-left: 100px;
    padding: 7px 5px 5px 5px;
    background-color: #fff;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.container_right_button {
    margin-right: 20px;
    padding: 7px 5px 5px 5px;
    background-color: #fff;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.prec {
    margin: 0 20px 5px 0;
}
.next {
    margin: 0 5px 5px 15px;
}
.title_nav {
    margin-right: 20px;
    margin-top: 15px;
}
.left_side_button {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    background: url('/src/img/return_button.svg') no-repeat;
}
.left_side_button:hover {
    background: url('/src/img/return_button_hover.svg') no-repeat;
}
.left_side_button:active {
    background: url('/src/img/return_button_clic.svg') no-repeat;
    transform: translate(4px, 4px);
}
.right_side_button {
    width: 40px;
    height: 40px;
    background: url('/src/img/right_button.svg') no-repeat;
}
.right_side_button:hover {
    background: url('/src/img/right_button_hover.svg') no-repeat;
}
.rigth_side_button:active {
    background: url('/src/img/right_button_clic.svg') no-repeat;
    transform: translate(4px, 4px);
}
.exit_tuto {
    position: absolute;
    top: 0;
    right: 0;
    margin: 25px 50px 0 0;
}

.exit_tuto:hover{
    font-weight: bold;
}