.keyboard_main{
    display: block;
    position: fixed;
    top: 25%;
    left: 25%;
    width:50%;
    height: 50%;
    z-index: 10;

}


.keyboard_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width:70%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(96, 100, 108, 0.8);
    border-radius: 10px;
    box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
}


.shortcut_img{
    width: 24px;
    height: 24px;
    margin-right: 50px;
}

.shortcut_line p{
    text-align: left;
}

.shortcut_line{
    width:80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color : rgb(235, 226, 226);
    font-weight: bold;
}


.shortcut_bouton_container{
    margin-top: 20px;
    margin-bottom: 4%;
    margin-left: auto;
    margin-right: auto;
    
}


.shortcut_img_lbl{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.shortcut_title{
    margin-left: auto;
    margin-right: auto;
    text-decoration: white underline;
  }