.container_compterendu p{
  font-size: 10;
}

.pdf{
    display: flex;
    flex-direction: column;
    width: 1170px;
    margin-left: auto;
    margin-right: auto;
    }
  
  .container_head, .container_middle, .container_foot {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 200px;
  }

  .container_foot{
    flex-direction: column;

  }

  .container_infoclient, .container_date, .pdf_totalval,.pdf_gold, .pdf_repartition, .pdf_leftdonut, .pdf_rightdonut{
    width: 50%;
  }

 .pdf_gold{
    margin-left: auto;
    margin-right: auto;
 }


 .pdf_p{
  margin-left: auto;
  margin-right: auto;
 }

 .pdf_row{
   display: flex;
   flex-direction: row;
   width: 100%;
 }


 .head_piechart{
   margin-bottom: -80px;
 }