.container_main_question{
    display:flex;
    flex-direction: row;
}


.question_leftside{
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}


.container_question{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(../../img/background_uni.png) no-repeat;
    background-size: cover;
    font-family: "Montserrat", sans-serif;
  background-color: #4db6eb;
}


.question_img{
    width: 50%;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}


.question_rightside{
    display: flex;
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 85%;
    background-color: rgba(255,255,255,0.5);
    border-style: none;
    border-radius: 15px;
    margin-top: 1%;
    margin-bottom: 1%;
    min-height: 45%;
    padding-top:10px;
    padding: 20px;
}

.base_type_end{
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-top: 1%;
  width: 100%;
  }


  .logo_questionnaire{
    position: absolute;
    top: 0;
    right: 0;
  }

  .questionnaire{
      font-size: 16px;
  }

  .questionnaire_page{
    text-align: center;
    padding: 20px;
  }