

.modal_main_search {
  position:fixed;
  background: white;
  top:2%;
  left:2%;
  color: black;
  border-radius: 25px;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
  padding: 10px;
  width: 96%;
  height: 90%;
}

.modal_position_search{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}


.findloinc_roller{
  position: absolute;
  z-index: 200;
  top: 50%;
  left: 50%;
}




.modal_btn_search{
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-top: 10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}


.container_search{
  display: flex;
  flex-direction: column;
}


.container_title_search{
  display: flex;
  flex-direction: row;

  margin-left:auto;
  margin-right: auto;
  margin-top:0.5%;
  margin-bottom:0.5%;


}

.modal_label_search{
  font-size: 25px;

}

.container_bloc_input{
  display: flex;
  flex-direction: row;

}


.container_bloc_btn{
  width:auto;
  margin-left:auto;
  margin-right: auto;

}

.search_nabm{
  text-decoration: underline #000 dashed;
}

.search_nabm:hover{
  cursor: help;
}
