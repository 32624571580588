


.container_filter{

}


.title_filter{
  cursor: pointer;
  margin-bottom: 0px;
  padding-left: 10px;
  font-weight: bold;
  font-size: 14px;
}


.information_table{
  margin-left: 4px;
}


.container_information_table{
  display: flex;
  align-items: center;
}
