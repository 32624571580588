/*********************/
/***GENERAL SECTION***/
/*********************/
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

.body_back{
  background: url(./img/background_uni.png) no-repeat;
  background-size: cover;
  font-family: "Montserrat", sans-serif;
background-color: #4db6eb;
}

p {
    color: black ;
}

.logo_onaos_mapping{
    width: 7%;
    margin-right: 30px;
}

.App {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;

}
hr {
    width: 30%;
    height: 2px;
    background-color: #545b6f;
    border: none;
}
h1 {
    font-size: 30px;
}
p {
    font-size: 16px;
}
.characters {
    font-weight: bold;
}
.margin_title {
    margin-block-start: 0em;
}
.title_client {
  /*  font-size: 50px;*/
    text-align: left;
    color: #545b6f;
}
.title_expert {
    text-align: left;
    color: #545b6f;
}
/*
.interligne {
    line-height: 1em;
}
.sizing_entree {
    padding-left: 60px;
}
*/
.resize {
    width: 100%;
    padding-bottom: 10px;
}
.title_propositions {
    font-size: 30px;
    color: #545b6f;
    text-align: left;
    margin-bottom: 30px;
}
/*
.alignement {
    text-align: left;
    margin-left: 20px;
}*/
.center {
    text-align: center;
}


/*********************/
/**CONTAINER SECTION**/
/*********************/
.container_onaos {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-flow: row wrap;
  /*  padding: 10% 5% 2% 5%;*/
    padding: 0% 3% 0% 3%;
}
.container_one_card {
    width: 600px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    color: #545b6f;
    text-align: left;
    font-size: 25px;
}
.container_client {
    width: 600px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    color: #545b6f;
    text-align: left;
    font-size: 25px;
}
.container_propositions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;

    width: 1000px;
    height: 400px;
    padding: 20px;
    background-color: #d9e8ff;
    color: #545b6f;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    overflow: scroll;
}
.container_propositions_cards {
    text-align: center;
    padding: 1%;
    margin-bottom: 20px;
    width: 300px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
}
.container_propositions_cards:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
}
.container_compteur {
    font-weight: bold;
    text-align: end;
    padding-right: 50px;
}
.container_final {
    margin: 50px 2% 0px 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
}
.container_final_propositions {
    width: 510px;/*600*/
    padding: 10px 30px;
    background-color: #ffffff;
    /*border: 2px solid #ffe140;*/
    border-radius: 20px;
  /*  box-shadow: 0px 0px 10px 0px rgba(242, 203, 136); */
    box-shadow: 0 0 8px rgba(0, 0, 0,0.2);
    text-align: left;
    font-size: 25px;
    color: #545b6f;
    margin-bottom: 20px;
}
.container_button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.container_final_propositions_adjust {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-flow: column wrap;
}
/**************/
/***ACCEPTER***/
/**************/
.agree {

    background-color: #4db6eb;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 5px;
}
.container_client_agree {
    width: 500px;
    background-color: #4db6eb;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 5px;
}
.img_agree {
    background: url(./img/verifier.svg) no-repeat;
    background-position-x: 10px;
    background-position-y: 10px;
    background-size: 50px;
}
/**************/
/***REFUSER****/
/**************/
.reject {
    background-color: #E13700;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 5px;
}
.container_client_reject {
    width: 500px;
    background-color: #E13700;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 5px;
}
.img_reject {
    background: url(./img/annuler.svg) no-repeat;
    background-position-x: 10px;
    background-position-y: 10px;
    background-size: 50px;
}
/****************/
/***ERROR LOG****/
/****************/
.errorLog {
    margin: 10px 0 0 0;
    color: #E31410;
}


@media (min-height: 1800px) {
  .resize{
    position:absolute;
    bottom:10%;
  }
}

.base_type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 15px;
}

.p_base_type{
  font-weight: bold;
  font-size: 18px;
  color: #315d79;
  margin-left: 1%;
}

.home{
  background: url('/src/img/home_btn.svg') no-repeat;
  width:40px;
  height: 40px;
  background-size: cover;
  margin-left: 20px;
  margin-right: 10px;
}




.home:hover{
  background: url('/src/img/home_btn_hover.svg') no-repeat;
  background-size: cover;
}


.home:active{
  background: url('/src/img/home_btn_clic.svg') no-repeat ;
  transform: translate(4px, -1px);
  width: 35px;
  height: 35px;
  margin-right: 5px;
}
