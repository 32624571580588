.duplicationvalidation_main{
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
    overflow: auto;
}

.display-block {
  display: block;
}


.duplicationvalidation_containier{

  justify-content: center;
  align-items: center;
  position:fixed;
  background: white;
  max-width: 70%;
  top:50%;
  left:50%;
  color: black;
  transform: translate(-50%,-50%);
  border-radius: 25px;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
  padding: 30px;
  max-height: 650px;
  overflow: auto;
}

.duplicationvalidation_texte{

}

.duplicatevalidation_btn{
  display: flex;
  justify-content: center;
}


.duplication_value{
  overflow: auto;
  max-height: 450px;
  background-color: #ffffff;
  margin-top:1%;
  margin-bottom:1%;
  padding-top: 1%;
}
