@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


.container_endform{
    /*ackground: url(../../img/background_mapper.png) no-repeat;
    background-size: cover;*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
}


.endform_position_card {
  width: 40%;
  border-style: none;
  border-radius: 25px;
  box-shadow: 0 5px 14px rgba(0,0,0,.15);
  padding: 10px;
  background-color: #fff;
  height: 350px; /*175px;*/
  display: flex;
  flex-direction: column;
  margin:2%;
  justify-content: space-around;
  padding: 20px;
}

.endform_position_card_hight{
  height: 500px; 
  padding-top: 30px;
}

.endform_position_card_large{
  width: 50%;
}

.container_end_line{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}



.end_interligne{
  text-align: center;
  line-height: 1.5em;
}

.title_end{
  color: #315D79  ;
  text-align: center;
  margin-bottom: 0;
margin-top: 10px;
}



.container_end_withoutbtn{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  background-color: rgba(255,255,255,0.5);
  border-style: none;
  border-radius: 25px;
  margin-top: 1%;
  margin-bottom: 1%;


}

.container_btn_endform{
  margin-left: auto;
  margin-right: auto;
  margin-top:1%;
  margin-bottom:1%;
}

.endform_roller{
  position: absolute;
  top:40%;
}


.text_download{
  color:#fff;
  font-size: 18px;
}



.base_type_end{
  display: flex;
flex-direction: row;
align-items: center;
justify-content: right;
margin-top: 1%;
width: 100%;
}


.export_center{
  text-align: center;
}