

.container_check_txt{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  margin-left:5px;
}


.checkboxandtxt{
  width: 100%;
  margin-bottom: 1%;
  display: flex;
flex-direction: row;
justify-content: space-between;
}

.container_check_txt .checkboxandtxt{
  border-bottom: 1px dashed;
  padding-bottom: 5px;
}


.inputdisabled{
  color: gray;
}


.allselect{
  justify-content: right;
  margin-right: 16px;
  margin-bottom: 0px;
}
