.chapitre_container{
  width: 42%;
  margin:auto;
  padding-bottom: 2%;
}


.chapitre_title{
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: justify;
}


.multiselect_container{
  border-radius: 8px;
}



.multiselect_container_red{
    border : 3px solid #EB6572 ;
  }
