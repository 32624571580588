
.search_history{
  width: 410px;
 border-top-width: 0;
 list-style: none;
 margin-top: 0;
 max-height: 150px;
 overflow-y: auto;
 position: absolute;
 background-color: #E6F5FF;
 border-radius: 5px;
 padding-left: 0px;

}

.search_history li {
  padding: 0.5rem;
}

.search_history-active,
.search_history li:hover {
  background-color: #8FA4B3;
  color: #e06328;;
  cursor: pointer;
  font-weight: 700;
}
