

.choixform{
    background-size: cover;
    font-family: "Montserrat", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    height: 100vh;
    margin-top: -100px;
  /*
    background: url(../../img/background_mapper.png) no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;*/
}


.choixform_position {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    border-style: none;
    border-radius: 25px;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    padding: 20px;
    background-color: #fff;
    max-height: 650px;
}

.choixform_position p {
    font-size: 16px;
    text-align: center;

}

.choixform_container{
      text-align: center;
}

.select_style{
  display: flex;
  margin:1%;
  justify-content: 'center';
  align-items: center;
}
.bold{
  font-weight: bold;
}

.centerselect {
    text-align: center;
    width:100%;
    margin-left:auto;
    margin-right: auto;
}

.choixform_disposition {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    width: 100%;
    height: 50px;
}

.choixform_disposition_line{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    justify-content: center;
}


.base_type_connection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 15px;
}

.logo_onaos_mapping_connection{
    width: 7%;
    margin-right: 30px;
}


.base_type_choice{
    justify-content: right;
}