.addorremove_supp_expert , .addorremove_add_expert{

}




.addorremove_supp_button {
    background: url('/src/img/supp_button.svg') no-repeat;
    width: 20px;
    height: 20px;
    background-size: cover;

}

.addorremove_supp_button:hover {
    background: url('/src/img/supp_button_hover.svg') no-repeat;
    background-size: cover;
}

.addorremove_supp_button:active {
    background: url('/src/img/supp_button_clic.svg') no-repeat;
    transform: translate(-2px, 0px);
    background-size: cover;
    width: 16px;
    height: 16px;
}



.addorremove_add_button{
  background: url('/src/img/add_button.svg') no-repeat;
  width:20px;
  height: 20px;
  background-size: cover;
}

.addorremove_add_button:hover{
  background: url('/src/img/add_button_hover.svg') no-repeat;
  background-size: cover;
}

.addorremove_add_button:active {
  background: url('/src/img/add_button_clic.svg') no-repeat ;
  transform: translate(-2px, 0px);
  background-size: cover;
  width: 16px;
  height: 16px;
}
