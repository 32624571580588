.btn_actions{



}



.btn_validation{


  margin-left: auto;
  margin-right: auto;
  width: auto;
  margin-bottom:10px;

}


.container_final_middle {
  margin: 50px 2% 0px 2%;
  display: flex;
  flex-direction: column;
}



.container_comm_btn{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.container_button_middle{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: auto;
}


.audit_containt{
  width: auto;
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 8px rgba(0,0,0,.2);
  height: auto;
}
