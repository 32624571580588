.supp_expert {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

.supp_button {
    background: url('/src/img/supp_button.svg') no-repeat;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 10px;

}

.supp_button:hover {
    background: url('/src/img/supp_button_hover.svg') no-repeat;
}

.supp_button:active {
    background: url('/src/img/supp_button_clic.svg') no-repeat;
    transform: translate(4px, 4px);
}





.checkbox_container{
  width: 40%;
  margin-left: auto;
  margin-right: auto;

}



._expert_account_title{
  text-align: center;
  color: white;
  font-size: 30px;
  margin-bottom:15px;
}
