.container_import_files{
  width: 90%;
  margin-left: auto;
  margin-right: auto;

}



.table_import_files{
  border-spacing: 0;
    background-color: #fff;
    margin-bottom:10px;
       overflow-y: auto;
       width: 100%;
           border: 1px solid black;

}

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-size: 12px;

}


tr:last-child td{
  border-bottom: 0;

}

tr td:last-child{
  border-right: 0;
}

tr th:last-child{
  border-right: 0;
}


.input_import{
  height: 30px;
  width: 200px;
}


.trash_import{
  height: 30px;
  width: 30px;
}



.trash_import:hover{
  transform: scale(1.15);
}


.error_input{
  border: 2px solid red;
}


.select_input{
  width: 200px;
  height:30px;
  text-align: center;

}

.error_select{
  border: 2px solid red;
  width: 200px;
  height: 38px;
}

.td_select_import{
  display: flex;
  justify-content: center;
  height: 36px;
}

