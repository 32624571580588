.font_color{
  color: #1A385E;
}
.logoRecupMail {
  width: 200px;
  margin-bottom: 10px;
}
.container_main_email{
  display:flex;
  flex-direction: row;
}
.container_msg{
  text-align: center;
  margin-top: 50px;
  min-height: 20px;
}
.container_msg p {
  color: #fff;
  font-size: 12px;
}
.recup_title {
  font-size: 24px;
  margin: 0;
}
.container_upperbox {
  width: 79%;
}
.upperbox {
  text-align: left;
  font-size: 14px;
  margin: 0;
}
.underbox {
  font-size: 14px;
  text-align: center;
}

.underboxbold {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}

.container_password_not_received p {
  display: inline;
  margin-right: 5px;
  font-size: 14px;
}
.container_password_not_received a {
  font-size: 14px;
  outline: none;
  text-decoration: none;
  font-weight: bold;
}
.container_return_connection {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.input_size_recup {
width: 25rem;
margin-top: 15px;
padding: 12px;
box-sizing: border-box;
outline: none;
font-size: 14px;
background: #fff;
border-radius: 15px;
border: none;
box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.return_button {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  background: url('/src/img/return_button.svg') no-repeat;
}
.return_button:hover {
  background: url('/src/img/return_button_hover.svg') no-repeat;
}
.return_button:active {
  background: url('/src/img/return_button_clic.svg') no-repeat;
  transform: translate(4px, 4px);
}
.recupForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.containerRecupForm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.recupMailContainer {
    width: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #4db6eb;
}


.fake_link{
  font-weight: bold;
}

.fake_link:hover{
  font-weight: bold;
  color:red;
  cursor: pointer;
}
