.account_checkbox_label {
  display: flex;
  flex-direction: row;
  margin: 5px auto;
  justify-content: space-between;
  width: 350px;
  font-size: 16px;
  font-weight: 550;
  color:#1A385E;
}


.account_checkbox_container{
  display: flex;
  flex-direction: row;
  margin: 5px auto;
  justify-content: center;
}


.account_checkbox_label_red{
  color: #EB6572 ;
}


.white_size{
  color:#fff;
  text-align: left;
  font-size: 14px;
}
