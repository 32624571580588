.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modal-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position:fixed;
  background: white;
  width: 30%;
  top:50%;
  left:50%;
  color: black;
  transform: translate(-50%,-50%);
  border-radius: 25px;
  box-shadow: 0px 5px 14px rgba(0,0,0,0.15);
  padding: 30px;
}

.modal-position{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
}

.modal-position button {
  margin: 0px 20px;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4db6eb;
  border: none;
  border-radius: 40px;
  box-shadow: 0 6px #999;
}

.modal-position button:hover {
  color: #fff;
  background-color: #00679b;
}

.modal-position button:active {
  background-color: #f5b23d;
  box-shadow: 0 3px #666;
  transform: translateY(4px);
}

.modal-label{
/*  display: flex;
  flex-direction: column;*/
  font-size: 20px;

  text-align: center;
}

.modal-label input {
  box-sizing: border-box;
  outline: none;
  border: 2px solid #1189de;
  border-radius: 4px;
  color: #292929;
  width: 100%;
  margin-top: 15px;
  padding: 12px;
  font-size: 18px;
  background: #fff;
  border-radius: 25px;
}

.modal-btn{
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-top: 10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.error {
  color:red;
  margin-top:20px
}

.selectbox_comm{
  width: 500px;
  margin-top:10px;
  margin-bottom: 10px;
  font-size: 16px !important;
}
