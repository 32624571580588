.input_questionnaire{
    width: 90%;
height: 80%;
margin-left: auto;
margin-right: auto;
border-radius: 10px;
border-style: none;
}



.container_text_questionnaire{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
}

.text_p_questionnaire{
    font-size: 16px;
        width: 96%;
    margin-left: auto;
    margin-right: auto;
}