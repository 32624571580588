@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

.dash_background{
/*    background: url(../../../img/background_mapper.png) no-repeat;
    background-size: cover;*/
  /*  height: 100vh;*/
    display: flex;
   flex-direction: column;
    justify-content: center;

}


.dash_navigation{
  margin-left: auto;
  margin-right: auto;
  padding-bottom : 0%;
}


.axonaute_roller{
  position: absolute;
  z-index: 200;
  top: 15%;
  left: 50%;
}
