.dropdown_filter ul {
  list-style: none;
  margin-top:10px;
  font-size: 12px;
}

.text_filter{
  padding-left: 5px;
  color:#e06328;
}

.text_filter:hover{
  color:#1b966b;
}

.filter_value{
  /*  float: right;*/
    padding-right: 25px;
        margin-left: auto;
}

.dropdown_filter ul {
  padding-left: 20px;
}

.dropdown_filter ul li {
  cursor: pointer;
  display: flex;
}

.dropdown_filter ul li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
