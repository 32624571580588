.questionnaire_container_btn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top:10px;
    padding-bottom: 10px;
}


.questionnaire_nombre{
    font-size: 16px;
    font-weight: bold;
}