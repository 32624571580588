

.container_comm_uniq{
  display: flex;
  flex-flow: row ;
  justify-content: space-between;

}



.icone_comm{
  margin-left: 50px;
  transition: transform 0.2s;

}

.icon_multi{
    margin-left: 20px;  
  }

.icone_comm:hover{
  transform: scale(1.3);
}

.icone_multi_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}