
.dash_container_client{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  padding: 30px;
}


.dash_client {
  text-align: center;
  padding: 1%;
  margin-right: 20px;
  margin-bottom: 20px;
 /* width: 25%;*/
  width:10%;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  height: 130px;
 /* height: 200px;*/

}

.dash_client_big{
  height: 200px;
  width: 25%;
}

.dash_client:hover {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  transform: scale(1.03);
}


.dash_clientactions {
  text-align: center;
  padding: 1%;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 300px;
  height: 200px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.dash_clientactions:hover {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  transform: scale(1.03);
}



.container_client_dico{

  height: 150px;
  overflow: auto;

}


.formation_container{
  display: flex;
flex-direction: column;
justify-content: space-between;
width: 15%;
}


.redreview{
  color: red;
  font-weight: bold;
}

.greenreview{
  color: green;
  font-weight: bold;
}



.boldreview{
  font-weight: bold;
}