.loincresult_table{
  border-spacing: 0;
    border: 1px solid black;
    background-color: #fff;
    margin-bottom:10px;
       overflow-y: auto;
       width: 100%;
}


tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-size: 12px;
  :last-child {
    border-right: 0;
  }
}


.restreint_cell{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loincresult_body{
  background-color: #fff;
    color:black;
    font-weight: bold;
}

.loincresult_body:hover{
  background-color: rgba(0, 0, 0, .15);

}

.loincresult_unrestreint:hover{
  background-color: rgba(0, 0, 0, .2);
}

.loincresult_unrestreint{
/*  background-color: #E6F5FF;*/
  color : rgba(0, 0, 0, 1);
}


.result_select{
  background-color: #CFFFCC;

}


.result_select:hover{
  background-color: #81B37D;
}

.icone_caution{
  padding-right: 10px;
}


.mouse_unrestreint:hover{
  cursor: help;
}



.tooltip_lines{
  white-space: pre-line;
}


.panelsearch_OuiNon,
.panelsearch_Oui{
  border-radius: 5px;
  padding: 2%;
  background-color: #88eb42;
  color: #fff;
}

.panelsearch_Voir:hover,
.panelsearch_OuiNon:hover,
.panelsearch_Oui:hover{
  box-shadow: 0 0 4px rgba(0,0,0,.3);
  cursor: pointer;
}

.panelsearch_OuiNon{
  background-color: #529E1C;
}


.panelsearch_Non{
  border-radius: 5px;
  padding: 2%;
  background-color: #eb7f59;
  color: #fff;
}


.panelsearch_Voir{
  border-radius: 5px;
  padding: 2%;
  background-color: #5984eb;
  color: #fff;
}


.Obligatoire{
  color: #eb7f59;

}

.margin_left{
  margin-left: 10px;
}

.container_panel_inactif{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.inactif_loinc{
  background-color: #a4ccc4;
}

.boldLOINC{
  font-weight: bold;
}