.container_account{
  display: flex;
  flex-direction: row;
}

.container_title_onaos{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.title_onaos{
  width: 150px;
  margin: 10px 25px 0 0;
  z-index: 100;
}
