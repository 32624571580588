input:focus {
    outline: none !important;
}

.account_input_container{
  display: flex;
  flex-direction: row;
  margin: 0 auto 25px auto;
  justify-content: center;
}

.account_input_full{
  width : 45%;
  font-size: 16px;
  border-radius: 15px;
  border-style: none;
  box-shadow: 0 0 15px rgba(0,0,0,0.15);
  height: 40px;
  padding-left: 10px;
}

.account_input_multiple_container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width : 46%;
}


.account_input_multiple{

  font-size: 16px;
  border-radius: 15px;
  border-style: none;
  box-shadow: 0 0 15px rgba(0,0,0,0.15);
  height: 40px;
  padding-left: 10px;

}


.input_error{
  border : 3px solid #EB6572;
}
