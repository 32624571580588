/*********/
/***MAP***/
/*********/

.tmp_recherche_dash_block{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
  align-items: center;
}
.full_container_propositions{
  width: 60%;
}



.container_propositions_map {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;

    width:97%;
    height: 520px;
    padding: 30px;
    background-color: #d9e8ff;
    color: #545b6f;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    /*overflow: scroll;*/
    overflow: auto;

}




.container_propositions_cards_map {
    text-align: center;
    padding: 1%;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 300px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}

.container_propositions_cards_map_scale{
  transition: transform 0.2s;

}

.container_propositions_cards_map_scale:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
    transform: scale(1.03);
}



.outloinc_client{
  background-color: #00000010;
}

.from_other_client{
  background-color: #FFC7BF;
}


.from_ca {
  background-color: #94dbce;
}

.add_client{
  background-color: rgba(250,250,250,0.8);
}


.container_one_card_map{
  width: 600px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  color: #545b6f;
  text-align: left;
  font-size: 25px;
}
.img_reject_test_map {
    background: url(../../img/annuler.svg) no-repeat;
    background-position-x: 0px;
    background-position-y: 0px;
    background-size: 20px;

}
.img_agree_test_map {
    background: url(../../img/verifier.svg) no-repeat;
    background-position-x: 0px;
    background-position-y: 0px;
    background-size: 20px;

}
.container_icon_LOINC{
  width: 20px;
  height: 20px;
}

.commun_map{
  padding-top: 2px;
padding-bottom: 2px;
}


.goldclient {
  background-color: #ebe536;

}


.nocode {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
}
.nocode img {
  width: 250px;
}
.nocode_text{
  font-weight: bold;
  font-size: 20px;
}


.croix{
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.outloinc{
  background-color: #9CB074;
}


.outloinc_nt{
  background-color: #aaa8aa55;
}
.outloinc_card{
  display: flex;
  flex-direction: column;
  min-height: 300px;
}


.outloinc_card_center{
 justify-content: center;
 
}

.nothing{
flex: 1 1 50px;

}

.duplicate_margin{
  margin-right: 15px;
}


.icone_duplicate{
  transition: transform 0.2s;
}

.icone_duplicate:hover{
  transform: scale(1.3);
}

.add_container{
  height: 400px;
  display: flex;
  justify-content: center;
}


.addLoinc_container{
  display: flex;
  height: 100%;
  justify-content: center;
  flex: 1 1 0;
}

.addLoinc {
  background: url(../../img/ajouter.png) no-repeat;
  width: 100px;
  height: 100px;
  background-size: 100px;
  margin-top: auto;
  margin-bottom: auto;

}

.addLoinc:hover {
  background: url(../../img/ajouter_hover.png) no-repeat;
  background-size: 100px;

}


.btn_search_container{
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: left;
}



.h_no_bottom{
  margin-bottom: 0px;
}