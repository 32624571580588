.container_invalid_page{
  width: 100%;
  height: 100vh;
  background: url(/src/img/background_uni.png) no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4db6eb;

}

.invalid_img{
  margin-right: 50px;
  border-radius: 15px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 30%);
}

.container_txt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #1A385E;
}

.container_txt h1 {
  font-size: 50px;
}


.container_return_validation_invalid{
  width: 100%;
  padding-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
}